import { EIcon, Icon } from '@arcanna/generic';
import { useJobIdFromUrl } from '@arcanna/hooks';
import { constructAIModelExportRequest } from '@arcanna/models/AIModels';
import { useExportAIModel } from '@arcanna/requests/AIModels';
import { IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import AlertDialog from 'src/_srcMUI/shared/components/AlertDialog';
import { DeleteButton } from 'src/_srcMUI/shared/components/Buttons';
import { DeleteModelRequest } from 'src/components/shared/models/job/DeleteModelRequest';
import { JobRollbackToModel } from 'src/components/shared/models/job/JobRollbackToModel';
import { ModelHistory } from 'src/components/shared/models/retrain-history/JobAllRetrainHistoryResponse';
import useDeleteModel from 'src/data-access/model-history/useDeleteModel';
import useRollbackToModel from 'src/data-access/model-history/useRollbackToModel';

type TModelHistoryActionColumnProps = {
  AIModel: ModelHistory;
  currentAIModelId: string | null;
};

function ModelHistoryActionColumn({ AIModel, currentAIModelId }: TModelHistoryActionColumnProps) {
  const { jobId } = useJobIdFromUrl();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [isConfirmUseInProductionDialogOpen, setIsConfirmUseInProductionDialogOpen] = useState<boolean>(false);

  const { mutateAsync: handleModelDelete } = useDeleteModel(jobId.toString());

  const { mutateAsync: mutateRollbackModel, isLoading: isRollbackLoading } = useRollbackToModel(jobId.toString());

  const { mutate: exportAIModel } = useExportAIModel({ jobId });

  const deleteModel = useCallback(() => {
    AIModel.modelId && handleModelDelete(new DeleteModelRequest(AIModel.modelId));
  }, [AIModel.modelId, handleModelDelete]);

  const isMenuOpen = Boolean(menuAnchorEl);
  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => setMenuAnchorEl(event.currentTarget);

  const handleCloseMenu = () => setMenuAnchorEl(null);

  const isAIModelCurrentlyInUse = useMemo(() => AIModel.modelId === currentAIModelId, [AIModel.modelId, currentAIModelId]);

  const renderMenuButton = useCallback(
    (iconName: EIcon, label: string, onMenuItemClick: (event: MouseEvent<HTMLLIElement>) => void, testId?: string) => {
      return (
        <MenuItem onClick={onMenuItemClick} className="MuiMenuItem-root--smaller" data-test-id={testId}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" gap={4} sx={{ padding: 0 }}>
            <Typography variant="subtitle2">{label}</Typography>
            <Icon name={iconName} fontSize="small" />
          </Stack>
        </MenuItem>
      );
    },
    []
  );

  const handleSaveRollbackModal = useCallback(() => {
    const jobRollbackModel = new JobRollbackToModel(AIModel.modelId ?? '');

    mutateRollbackModel(jobRollbackModel, {
      onSuccess: () => {
        setIsConfirmUseInProductionDialogOpen(false);
      }
    });
  }, [AIModel.modelId, mutateRollbackModel]);

  const handleAIModelExport = useCallback(() => {
    exportAIModel(constructAIModelExportRequest({ modelId: AIModel.versionId ?? '' }));
  }, [AIModel.versionId, exportAIModel]);

  if (!AIModel.isRevertible) {
    return <></>;
  }

  return (
    <>
      <IconButton
        type="button"
        size="small"
        variant="text"
        color="secondary"
        onClick={handleOpenMenu}
        data-test-id="dropdown-menu"
        sx={{
          fontSize: '16px'
        }}
      >
        <Icon name={EIcon.ThreeDots} fontSize="inherit" />
      </IconButton>
      <Menu
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {!isAIModelCurrentlyInUse &&
          renderMenuButton(EIcon.Rocket, 'Use in Production', () => setIsConfirmUseInProductionDialogOpen(true))}
        {renderMenuButton(EIcon.Share, 'Export AI Model', handleAIModelExport)}
        {!isAIModelCurrentlyInUse && (
          <DeleteButton
            handleDelete={deleteModel}
            renderCustomButton={(handleModalOpen: () => void) => renderMenuButton(EIcon.Delete, 'Delete', handleModalOpen)}
            modalBody={'Are you sure you want to delete this AI model?'}
          />
        )}
      </Menu>
      <AlertDialog
        open={isConfirmUseInProductionDialogOpen}
        handleSubmit={handleSaveRollbackModal}
        isLoading={isRollbackLoading}
        onClose={() => setIsConfirmUseInProductionDialogOpen(false)}
        modalBody={
          <Stack direction="column">
            <Typography variant="body2" color="inherit">
              Switching to another model version will result in a series of changes
            </Typography>
            <Typography variant="body2" marginTop={1} color="inherit">
              During this update:
            </Typography>
            <ul>
              <li>The job will be disabled</li>
              <li>Existing feedback will be updated</li>
              <li>You will not be able to perform any actions on the job</li>
            </ul>
            <Typography variant="body2" color="inherit">
              Do you wish to continue?
            </Typography>
          </Stack>
        }
        mode="confirm"
      />
    </>
  );
}

export default ModelHistoryActionColumn;
