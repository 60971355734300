import { JsonObject, JsonProperty } from 'json2typescript';
import { JobCreateParameterRecord } from './JobCreateParameterRecord';
import { JobIntegrationThrottleRecord } from './JobIntegrationThrottleRecord';
import { IntegrationDecisionPointRecord } from '../integration/subcategory/IntegrationDecisionPointRecord';

@JsonObject('JobCreateIntegrationRecord')
export class JobCreateIntegrationRecord {
  autoId?: number = undefined;

  @JsonProperty('id', Number)
  id?: number = undefined;

  @JsonProperty('title', String)
  title?: string = undefined;

  @JsonProperty('context_id', String, true)
  context_id?: string = undefined;

  @JsonProperty('params', [JobCreateParameterRecord])
  params?: JobCreateParameterRecord[] = [];

  @JsonProperty('throttle', JobIntegrationThrottleRecord, true)
  throttle?: JobIntegrationThrottleRecord = undefined;

  @JsonProperty('decision_points', [IntegrationDecisionPointRecord], true)
  decision_points?: IntegrationDecisionPointRecord[] = undefined;

  @JsonProperty('storage_tag', String, true)
  storage_tag?: string = undefined;

  constructor(
    id: number,
    params: JobCreateParameterRecord[],
    title?: string,
    contextId?: string,
    throttle?: JobIntegrationThrottleRecord,
    decisionPoints?: IntegrationDecisionPointRecord[]
  ) {
    this.autoId = Math.random();
    this.id = id;
    this.params = params;
    this.title = title;
    this.context_id = contextId;
    this.throttle = throttle;
    this.decision_points = decisionPoints;
  }
}
