import { StorageTagRecord } from '@arcanna/models/StorageTags';
import { createColumnHelper } from '@tanstack/react-table';
import StorageTagActions from './components/StorageTagActions';
import StorageTagInputs from './components/StorageTagInputs';
import { formatBytes } from '@arcanna/utils';
import StorageTagDeletionStatusComponent from './components/StorageTagDeletionStatusComponent';

export const getColumns = () => {
  const columnHelper = createColumnHelper<StorageTagRecord>();

  return [
    columnHelper.accessor('storageTag', {
      id: 'storageTag',
      header: 'Name',
      cell: ({ row }) => row.original.storageTag,
      meta: {
        isTooltipDisabled: true
      }
    }),
    columnHelper.accessor('deletionStatus', {
      id: 'deletionStatus',
      cell: ({ row }) => <StorageTagDeletionStatusComponent storageTagDeletionStatus={row.original.deletionStatus} />,
      header: 'Soft Delete',
      meta: {
        isTooltipDisabled: true
      }
    }),
    columnHelper.accessor('eventsCount', {
      id: 'eventsCount',
      cell: ({ row }) => row.original.eventsCount,
      header: 'Events',
      meta: {
        isTooltipDisabled: true
      }
    }),
    columnHelper.accessor('size', {
      id: 'size',
      cell: ({ row }) => formatBytes(row.original.size),
      header: 'Size',
      meta: {
        isTooltipDisabled: true
      }
    }),
    columnHelper.accessor('inputs', {
      id: 'inputs',
      cell: ({ row }) => <StorageTagInputs integrationAutoIds={row.original.inputs} />,
      header: 'Inputs',
      meta: {
        isTooltipDisabled: true
      }
    }),
    columnHelper.display({
      id: 'actions',
      maxSize: 40,
      cell: ({ row }) => <StorageTagActions storageTag={row.original} />
    })
  ];
};
