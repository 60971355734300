import { Table } from '@arcanna/generic';
import { useJobIdFromUrl } from '@arcanna/hooks';
import { Stack, useTheme } from '@mui/material';
import { getCoreRowModel, getPaginationRowModel, getSortedRowModel } from '@tanstack/react-table';
import { useReactTable } from '@tanstack/react-table';
import { useMemo } from 'react';
import { ModelHistory } from 'src/components/shared/models/retrain-history/JobAllRetrainHistoryResponse';
import { useJobAllRetrainHistory, useJobInfo } from 'src/data-access';
import { getColumns } from './ModelManagementTable.utils';

function ModelManagementTable() {
  const { jobId } = useJobIdFromUrl();
  const theme = useTheme();

  const { data: modelsResponse, isLoading: isModelsLoading } = useJobAllRetrainHistory(jobId);
  const { data: jobInfo, isLoading: isJobInfoLoading } = useJobInfo(jobId);

  const currentAIModel = useMemo(
    () => jobInfo?.info?.processor?.lastModelPath?.split('.')[0] ?? null,
    [jobInfo?.info?.processor?.lastModelPath]
  );

  const columns = useMemo(() => getColumns(currentAIModel, theme), [theme, currentAIModel]);

  const tableInstance = useReactTable<ModelHistory>({
    getCoreRowModel: getCoreRowModel(),
    data: modelsResponse?.models ?? [],
    columns,
    enableMultiRowSelection: false,
    enableRowSelection: false,
    enableColumnResizing: false,
    enableRowPinning: false,
    enableSorting: true,
    enableColumnPinning: false,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  });

  return (
    <Stack gap={1}>
      <Table.component<ModelHistory>
        tableInstance={tableInstance}
        isTableResizable={false}
        isLoading={isModelsLoading || isJobInfoLoading}
        noResultsMessageTitle={'No results found'}
        noResultsMessageSubtitle={'Try to adjust your search criteria.'}
        getIsRowDisabled={(row) => !row.original.isRevertible}
      />
      <Table.TablePagination<ModelHistory> tableInstance={tableInstance} canChangePageSize={false} />
    </Stack>
  );
}

export default ModelManagementTable;
