/**
 * If the REACT_APP_BACKEND_URL variable is 'null'
 * then the backend URL is current url + '/api' suffix
 */
let backendUrl = window._env_.REACT_APP_BACKEND_URL;

if (!window._env_.REACT_APP_BACKEND_URL) {
  backendUrl = window.location.origin;
}

// Transform secondary url to list
let backendSecondaryUrlsArray: string[] = [];
const backendSecondaryUrls = window._env_.REACT_APP_BACKEND_SECONDARY_URLS;
if (backendSecondaryUrls != null) {
  backendSecondaryUrlsArray = backendSecondaryUrls.split(',');
}

if (window._env_.REACT_APP_BACKEND_URL_SUFFIX) {
  backendUrl += window._env_.REACT_APP_BACKEND_URL_SUFFIX;
}

for (let i = 0; i < backendSecondaryUrlsArray.length; i++) {
  backendSecondaryUrlsArray[i] = backendSecondaryUrlsArray[i] + window._env_.REACT_APP_BACKEND_URL_SUFFIX;
}
// Add default url to secondary list as well
backendSecondaryUrlsArray.push(backendUrl);

const restApiToken = window._env_.REACT_APP_REST_API_TOKEN ? window._env_.REACT_APP_REST_API_TOKEN : 'api_token';
const monitoringApiKey = window._env_.REACT_APP_MONITORING_API_KEY
  ? window._env_.REACT_APP_MONITORING_API_KEY
  : 'monitoring_api_key';

const defaultConfig = {
  backendUrl: localStorage.getItem('backendUrl') || backendUrl,
  backendSecondaryUrlsArray,
  apmActive: JSON.parse(window._env_.REACT_APP_APM_ACTIVE),
  apmServerUrl: window._env_.REACT_APP_APM_SERVER_URL,
  apmServiceName: window._env_.REACT_APP_APM_SERVICE_NAME,
  openStreetMapService: window._env_.REACT_APP_OPEN_STREETMAP_SERVICE,
  restApiToken,
  monitoringApiKey,
  i18n: {
    lng: 'en',
    fallbackLng: 'en',
    whitelist: ['en', 'ro']
  },
  api: {
    userAuth: '/user/auth',
    userAuthAd: '/user/auth-ad',
    categoryList: '/category/list',
    clustersList: '/clusters',
    clustersIdsSuggestions: '/clusters/suggestions',
    clustersFilterValues: '/clusters/filters/values',
    contextEnrichmentSave: '/context-enrichment/:jobId/save',
    contextEnrichmentFetch: '/context-enrichment/:jobId/fetch',
    contextEnrichmentRequestDetails: '/context-enrichment/:jobId/request-details',
    contextEnrichmentById: '/context-enrichment/:jobId/:enrichmentId',
    contextEnrichmentList: '/context-enrichment/:jobId/list',
    contextEnrichmentLinearise: 'context-enrichment/linearise-response',
    contextEnrichmentUpdateStatus: '/context-enrichment/:jobId/update-status',
    jobsSummary: '/job/summary',
    jobsSummaryByCategory: '/job/summary/:categoryId',
    jobClone: '/job/:jobId/clone',
    eventInfo: '/event-explorer/event-info/:eventId',
    eventExplorerBucketInfo: '/event-explorer/bucket-info/:bucketId',
    feedbackStatisticsUsers: '/feedback/statistics/users',
    feedbackUsers: '/feedback/users',
    feedbackStatisticsBucketsCount: '/feedback/statistics/buckets-count',
    feedbackStatisticsEventsBreakdown: '/feedback/statistics/events-breakdown',
    feedbackStatisticsBucketsStatesBreakdown: '/feedback/statistics/buckets-states-breakdown',
    feedbackStatisticsConsensus: '/feedback/statistics/consensus',
    feedbackStatisticsProgress: '/feedback/statistics/progress',
    feedbackStatisticsModelAccuracy: '/feedback/statistics/model-accuracy',
    feedbackStatisticsKbIncrease: '/feedback/statistics/kb-increase',
    feedbackStatisticsLabelsUsed: '/feedback/statistics/labels-used',
    feedbackStatisticsMislabeled: '/feedback/statistics/mislabeled',
    feedbackStatisticsComputeMetrics: '/feedback/statistics/compute-metrics',
    feedbackBucketsBatch: '/feedback/buckets/batch',
    feedbackBucketsAlerts: '/feedback/buckets/alerts',
    feedbackBucketDetails: '/feedback/buckets/:bucketId',
    feedbackBucketsUnifiedBatch: '/feedback/buckets-unified/batch',
    feedbackBucketsOverwrite: '/feedback/buckets/overwrite',
    feedbackBucketSimilarity: '/feedback/buckets/:bucketId/similarity',
    feedbackBucketsUpdate: '/feedback/buckets/update',
    feedbackFiltersFields: '/feedback/filters/fields',
    generateBucketSummary: '/llm/summarization/bucket/:bucketId',
    clearFeedback: 'feedback/buckets/clear-feedback',
    integrationList: '/integration/list/:integrationId',
    integrationDelete: '/integration/delete/:integrationId',
    integrationAdd: '/integration/create',
    integrationUpdate: '/integration/update/:integrationId',
    integrationCheck: '/integration/check',
    integrationSubcategoryList: '/integration/subcategory/list/:subcategoryId',
    integrations: '/integration/summary',
    integrationSummary: '/integration/summary/:parameterType',
    integrationSummaryInput: '/integration/summary/input',
    integrationParametersOptions: '/integration/parameters/options',
    jobAlertsBatch: '/job/alerts/batch',
    jobAlertsBatchByAlertType: '/job/alerts/batch/:alertsType',
    jobAlertsStructure: '/job/alerts/structure',
    jobGraphStats: '/job/graph-stats',
    jobInfo: '/job/info/:id',
    jobJourney: '/job/info-journey/:id',
    jobHistory: '/job/:id/alert/:alertId/history',
    jobAlertsTimeseries: '/job/alerts/timeseries',
    bucketAlertsTimeseries: '/feedback/statistics/events-stats',
    jobGeneralStats: '/job/general-stats',
    jobRawAlert: '/job/:id/alert/:alertId/raw',
    jobGenericAlertsBatch: '/job/generic-alerts/batch',
    jobChangeStatus: '/job/:action',
    jobStatus: '/job/status/:id',
    jobRetrain: '/job/retrain',
    jobRetrainMultiple: '/job/retrain-multiple',
    isJobRetrainAvailable: '/job/retrain/enabled',
    jobUpdate: '/job/update/:id',
    jobIntegrationUpdateOnJob: '/job/update/:id/integration',
    jobCleaningResults: '/job/cleaning-results',
    jobIndicators: '/job/indicators',
    jobFeatureSelectionUnifiedUpdate: '/job/feature-selection/bulk/update',
    jobFeatureSelectionUnifiedSummary: '/job/feature-selection/group/summary',
    jobFeatureSelectionSummary: '/job/feature-selection/summary',
    jobFeatureSelectionUpdate: '/job/feature-selection/update',
    fieldInsights: '/job/feature-selection/stats',
    jobAlertRandom: '/job/:jobId/alert/random',
    jobColumnsSelectionSummary: '/job/column-selection/summary',
    jobColumnsSelectionUpdate: '/job/column-selection/update',
    subcategoryList: '/subcategory/list',
    actionAggregations: '/action/aggregations',
    actionCategories: '/action/categories',
    actionList: '/action/list',
    componentAvailability: '/component/availability',
    bucketsRequiringFeedback: '/feedback/statistics/feedback-required',
    currentFeedbackBreakdown: '/feedback/statistics/current-feedback-breakdown',
    customFieldOperators: '/job/custom-field/operators',
    customFieldNextSequence: '/job/custom-field/next-sequence-number',
    createCustomField: '/job/custom-field/create',
    updateCustomField: '/job/custom-field/update',
    summaryCustomField: '/job/custom-field/summary',
    jobOverviewSplitStats: '/job/alerts/split-stats',
    confusionMatrix: 'feedback/statistics/confusion-matrix',
    staticRuleList: '/static-rule/list',
    updateStaticRule: '/static-rule/update',
    deleteStaticRule: '/static-rule/delete/:id',
    eventExplorerStructure: '/event-explorer/structure',
    makeAWish: '/make-a-wish/',
    roleNames: '/user/role_names',
    startJob: '/job/start',
    stopJob: '/job/stop',
    deleteJob: '/job/delete',
    authCheckKeepAlive: '/user/auth_check/keepalive',
    jobCreate: '/job/create',
    jobTestFirstBatch: '/job/test-input',
    jobMetricsUpdate: '/job/time-units/update',
    overallStatisticsDecisions: '/impact-dashboard/overall-statistics/decisions/:jobId',
    overallStatisticsPipeline: '/impact-dashboard/overall-statistics/pipeline/:jobId',
    overallStatisticsTimeMetrics: '/impact-dashboard/overall-statistics/time-metrics/:jobId',
    jobStatisticsTopJobs: '/impact-dashboard/job-statistics/top-jobs',
    jobStatisticsAcumulativeTimeReduced: '/impact-dashboard/job-statistics/accumulative-time-reduced',
    jobStatisticsFatigueRisk: '/impact-dashboard/job-statistics/fatigue-risk',
    jobStatisticsModelAccuracy: '/impact-dashboard/job-statistics/model-accuracy',
    platformSummary: '/platform/summary',
    eventExplorerFiltersFields: '/event-explorer/filters/fields',
    eventExplorerFiltersValues: '/event-explorer/filters/values',
    eventExplorerQuickFilters: '/event-explorer/quick-filters',
    eventExplorerBatch: '/event-explorer/batch',
    eventExplorer: {
      list: '/event-explorer/batch',
      filtersFields: '/event-explorer/filters/fields',
      filtersValues: '/event-explorer/filters/values',
      quickFilters: '/event-explorer/quick-filters'
    },
    feedback: {
      add: '/api/v2/events/feedback/add',
      remove: '/api/v2/events/feedback/remove'
    },
    events: {
      batch: '/api/v2/events/batch',
      fields: '/api/v2/events/fields',
      fieldsUpdate: '/api/v2/events/fields/update',
      reprocess: '/api/v2/events/reprocess',
      groupMetrics: '/api/v2/events/field/group/metrics',
      groupFields: '/api/v2/events/field/groups',
      getExpandedEvent: '/api/v2/events/get_event',
      similarity: '/api/v2/events/similarity'
    },
    aiModel: {
      export: (jobId: string | number) => `/api/v2/job-model/${jobId}/export`,
      import: (jobId: string | number) => `/api/v2/job-model/${jobId}/import`,
      importSummary: (jobId: string | number) => `/api/v2/job-model/${jobId}/import-summary`
    },
    eventsFeedback: '/events/feedback',
    eventsUpdateCluster: '/events/update-cluster',
    jobActivityLog: '/job/:id/activity-log',
    jobAllRetrainHistory: '/model-history/:jobId',
    jobModel: '/model-history/:jobId/:modelId',
    jobExportModelKB: '/model-history/:jobId/:modelId/export_kb',
    jobModelDownload: '/model-history/:jobId/:modelId/download',
    jobRollbackModel: '/model-history/:jobId/:modelId/rollback',
    jobRetrainMetrics: '/model-history/:jobId/metrics',
    migrationStatus: '/migration/status',
    jobFeedbackFilterFields: '/feedback/filters/fields',
    unified: {
      filterFields: '/feedback/filters/unified/fields',
      filterValues: '/feedback/filters/unified/values'
    },
    storageManagement: {
      delete: ({ jobId }: { jobId: string | number }) => `/api/v2/job/${jobId}/storage`,
      info: ({ jobId }: { jobId: string | number | null }) => `/api/v2/job/${jobId}/storage-info`,
      softDelete: ({ jobId }: { jobId: string | number }) => `/api/v2/job/${jobId}/storage/soft-delete`
    },
    flows: {
      order: ({ jobId }: { jobId: string | number }) => `/api/v2/job/${jobId}/integration/order`,
      addEdit: ({ jobId }: { jobId: string | number }) => `/api/v2/job/${jobId}/integration/update`,
      delete: ({ jobId }: { jobId: string | number }) => `/api/v2/job/${jobId}/integration/delete`,
      enableDisable: ({ jobId }: { jobId: string | number }) => `/api/v2/job/${jobId}/integration/enable`,

      codeBlock: {
        save: '/code-exec/save',
        run: '/code-exec/test'
      },

      contextEnrichment: {
        save: ({ jobId }: { jobId: string | number }) => `/context-enrichment/${jobId}/save`,
        run: ({ jobId }: { jobId: string | number }) => `/context-enrichment/${jobId}/fetch`,
        requestDetails: ({ jobId }: { jobId: string | number }) => `/context-enrichment/${jobId}/request-details`,
        linearise: 'context-enrichment/linearise-response'
      },
      integrationSubcategoryDecisionPoints: {
        get: '/api/v2/integration/subcategory/decision-points'
      }
    },
    job: {
      updateSettings: (jobId: number) => `job/update-settings/${jobId}`,
      bulkStart: 'job/start-multiple',
      bulkStop: 'job/stop-multiple',
      bulkDelete: 'job/delete-multiple',
      addCustomLabel: ({ jobId }: { jobId: string | number }) => `/api/v2/job/${jobId}/custom-labels/add`,
      clone: ({ jobId }: { jobId: string | number }) => `/api/v2/job/${jobId}/clone`
    },
    LLMChat: {
      gets: 'llm/chat/summary',
      get: (chatId: string) => `llm/chat/${chatId}`,
      edit: (chatId: string) => `llm/chat/${chatId}/edit`,
      delete: (chatId: string) => `llm/chat/${chatId}`,
      sendMessage: 'llm/chat/send-message',
      stopGeneration: (chatId: string | null) => `llm/chat/${chatId}/stop-generation`
    },
    platformSettings: {
      info: '/platform/settings',
      save: '/platform/settings/update',
      dataSharingSave: 'platform/settings/telemetry-opt-in'
    },
    licenseInfo: '/platform/license',
    licenseLoad: '/platform/license/load',
    userManagement: {
      list: '/user/summary',
      delete: '/user/delete',
      create: '/user/create',
      updateRoles: '/user/update-roles'
    },
    auth: {
      changePassword: '/user/auth/change-password'
    },
    apiKeys: {
      list: '/user-key/list',
      create: '/user-key/create',
      delete: '/user-key/delete'
    },
    overview: {
      arcannaSavings: '/api/v2/overview/savings',
      processedDetails: '/api/v2/overview/processed-details',
      actionCenter: '/api/v2/overview/action-center',
      accuracyMetrics: '/api/v2/overview/accuracy-metrics',
      decisionsMetrics: '/api/v2/overview/decisions-metrics',
      decisionsUpdates: '/api/v2/overview/decisions-updates',
      usersPerformance: '/api/v2/overview/users-performance',
      decisionsBreakdown: '/api/v2/overview/decisions-breakdown',
      feedbackCoverage: '/api/v2/overview/feedback-coverage'
    }
  },
  routes: {
    redirect: '/redirect',
    homepage: '/',
    homepageDashboard: '/home-dashboard',
    monitoring: '/monitoring',
    monitoringDrilldown: '/monitoring/:componentType/:id',
    platformManagement: '/platform-management',
    charts: '/usecases/charts/summary',
    chartViewer: '/usecases/charts/view/:category/:chartId',
    useCases: '/usecases',
    exportCsv: '/csv-exports',
    reporting: '/reporting',
    reportingEditor: '/csv-export-edit/:id',
    reportingAdd: '/csv-export-add/:type',
    management: '/management',
    users: '/users',
    userManagement: '/user-management',
    apiKeys: '/api-keys',
    jobsAll: '/use-cases',
    feedback: '/use-cases/feedback/:id',
    eventExplorerColumnsSelection: '/use-cases/event-explorer-columns-selection/:id',
    integrations: {
      base: '/integrations',
      add: (subcategoryId: string | number) => `/integrations/new/${subcategoryId}`,
      edit: (integrationId: string | number) => `/integrations/${integrationId}/edit`
    },
    flowsWrapper: '/use-cases-flows',
    flows: '/use-cases-flows/:id',
    flowsOld: '/use-cases/flows-old/:id',
    flowsComponents: {
      codeBlock: {
        add: ({ jobId }: { jobId: string | number }) => `/use-cases-flows/${jobId}/code-block/add`,
        edit: ({ jobId, codeBlockId }: { jobId: string | number; codeBlockId: string | number }) =>
          `/use-cases-flows/${jobId}/code-block/edit/${codeBlockId}`
      },
      contextEnrichment: {
        add: ({ jobId }: { jobId: string | number }) => `/use-cases-flows/${jobId}/context-enrichment/add`,
        edit: ({ jobId, contextId }: { jobId: string | number; contextId: string }) =>
          `/use-cases-flows/${jobId}/context-enrichment/edit/${contextId}`
      }
    },
    eventExplorer: '/use-cases/event-explorer/:id',
    feedbackFlow: '/use-cases/feedback-flow/:id',
    feedbackUnified: '/use-cases/feedback-unified/:ids',
    staticRulesJob: '/use-cases/static-rule/:id',
    columnsSelection: '/use-cases/columns-selection/:id',
    dashboardJob: '/use-cases/dashboard/:id',
    jobOverView: '/use-cases/overview/:id',
    retrainJob: '/use-cases/retrain/:id',
    editJob: '/use-cases/edit/:id',
    jobClusters: '/use-cases/clusters/:id',
    featureSelectionJob: '/use-cases/decision-points/:id',
    decisionPointsUnified: '/use-cases/decision-points-unified/:ids',
    jobCreate: '/use-cases/create',
    login: '/login',
    changePassword: '/change-password',
    authenticationOidc: '/oidc-auth',
    termsAndConditions: '/terms/END USER LICENSE AGREEMENT - Siscale.pdf',
    csv: '/csv',
    csvEditor: '/csv/editor/:id',
    kibana: '/kibana',
    page404: '/404',
    documentationSecondary: '/guides/InfraAI Access Guide.pdf',
    support: 'https://www.arcanna.ai/support',
    pricing: 'https://www.arcanna.ai/pricing',
    unavailable: '/unavailable',
    auth: {
      changePassword: '/change-password'
    }
  },
  defaultDateTimeFormat: 'DD/MM/YYYY HH:mm',
  defaultEventsCount: 1000,
  defaultLanguageId: 1,
  requestTimeout: 20000,
  branding: {
    folder: window._env_.REACT_APP_BRANDING_FOLDER || 'siscale'
  },
  docs: {
    home: 'https://docs.arcanna.ai',
    upgradeGuide: 'https://docs.arcanna.ai/docs/admin-guide/installation',
    releaseNotes: 'https://docs.arcanna.ai/docs/release_notes',
    flows: {
      contextEnrichment: 'https://docs.arcanna.ai/docs/user-guide/context-enrichment',
      contextEnrichmentGather: 'https://docs.arcanna.ai/docs/user-guide/context-enrichment/jinja-templates',
      codeBlock: 'https://docs.arcanna.ai/docs/user-guide/code-block',
      // TODO: add proper links here for add integration modal in flows when ready
      input: 'https://docs.arcanna.ai/docs/concepts/integrations',
      postDecision: 'https://docs.arcanna.ai/docs/concepts/integrations',
      caseCreation: 'https://docs.arcanna.ai/docs/concepts/integrations',
      enrichment: 'https://docs.arcanna.ai/docs/concepts/integrations'
    }
  }
};

export const config = defaultConfig;
