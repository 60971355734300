import { Stack, Theme, Tooltip, Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { ModelHistory } from 'src/components/shared/models/retrain-history/JobAllRetrainHistoryResponse';
import ModelHistoryActionColumn from './components/ModelHistoryActionColumn';

export const getColumns = (currentAIModel: string | null, theme: Theme) => {
  const columnHelper = createColumnHelper<ModelHistory>();

  return [
    columnHelper.accessor('modelId', {
      id: 'modelId',
      header: () => (
        <Typography variant="subtitle2" fontWeight={500} marginLeft={'20px'}>
          AI Model Name
        </Typography>
      ),
      cell: ({ row }) => (
        <Tooltip
          placement="bottom"
          enterDelay={1000}
          enterNextDelay={1000}
          componentsProps={{
            tooltip: {
              sx: {
                background: theme.palette.secondary[600]
              }
            }
          }}
          title={row.original.isRevertible ? '' : 'This model version is no longer available'}
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <Stack
              sx={{
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                background: row.original.modelId === currentAIModel ? theme.palette.primary.main : 'transparen'
              }}
            />
            <Typography variant="caption">{row.original.modelId}</Typography>
          </Stack>
        </Tooltip>
      ),
      size: 140,
      enableHiding: false,
      enableResizing: false
    }),
    columnHelper.accessor('trainDate', {
      id: 'trainDate',
      header: 'Trained On',
      cell: ({ row, cell }) => (
        <Tooltip
          placement="bottom"
          enterDelay={1000}
          enterNextDelay={1000}
          componentsProps={{
            tooltip: {
              sx: {
                background: theme.palette.secondary[600]
              }
            }
          }}
          title={row.original.isRevertible ? '' : 'This model version is no longer available'}
        >
          <Stack component="span">{moment(cell.getValue()).format('MMM DD, YYYY HH:mm')}</Stack>
        </Tooltip>
      ),
      size: 140,
      enableHiding: false,
      enableResizing: false
    }),
    columnHelper.display({
      id: 'actions',
      cell: ({ row }) => <ModelHistoryActionColumn AIModel={row.original} currentAIModelId={currentAIModel} />,
      size: 20
    })
  ];
};
