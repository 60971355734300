import { AlertDialog } from '@arcanna/components';
import { AIModelImportSummaryResponse } from '@arcanna/models/AIModels';
import { Divider, Stack, TextField, Typography, useTheme } from '@mui/material';
import _ from 'lodash';
import { EIcon } from '../../../../../../generic/Icon';
import { useEffect, useState } from 'react';
import { Label } from '../../../../../../generic/Label';

type TModelUploadConfirmModalProps = {
  confirmModelData: AIModelImportSummaryResponse | null;
  handleFileImport: (fileName: string) => void;
  onClose: () => void;
};

function ModelUploadConfirmModal({ confirmModelData, handleFileImport, onClose }: TModelUploadConfirmModalProps) {
  const theme = useTheme();
  const [fileName, setFileName] = useState<string>('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (!confirmModelData) {
      setFileName('');
    }
  }, [confirmModelData]);

  const handleSubmit = () => {
    if (!fileName) {
      setError('This field is required');

      return;
    }

    return handleFileImport(fileName);
  };

  return confirmModelData ? (
    <AlertDialog
      open={!_.isEmpty(confirmModelData)}
      handleSubmit={handleSubmit}
      onClose={onClose}
      title="Arcanna AI Model Summary"
      mode="confirm"
      iconName={EIcon.Info}
      confirmBtnText="Yes, Import"
      modalBody={
        <Stack direction="column">
          <Stack direction="column" overflow="scroll" maxHeight="420px">
            <Typography variant="subtitle1" color={theme.palette.secondary[300]} mb={1}>
              The AI Model you uploaded contains:
            </Typography>
            <Typography variant="subtitle1" color={theme.palette.secondary[100]}>
              Decisions:
            </Typography>
            {confirmModelData?.customLabels?.length ? (
              <ul>
                {confirmModelData?.customLabels?.map((customLabel) => (
                  <Typography component="li" key={customLabel.id} variant="subtitle1" color={theme.palette.secondary[300]}>
                    {customLabel.name}
                  </Typography>
                ))}
              </ul>
            ) : (
              <Typography variant="subtitle1" color={theme.palette.secondary[300]} paddingLeft={3} mb={1}>
                No decisions.
              </Typography>
            )}

            <Typography variant="subtitle1" color={theme.palette.secondary[100]}>
              Decision Points:
            </Typography>
            {confirmModelData?.features?.length ? (
              <ul>
                {confirmModelData?.features?.map((feature) => (
                  <Typography component="li" key={feature} variant="subtitle1" color={theme.palette.secondary[300]}>
                    {feature}
                  </Typography>
                ))}
              </ul>
            ) : (
              <Typography variant="subtitle1" color={theme.palette.secondary[300]} paddingLeft={3} mb={1}>
                No decision points.
              </Typography>
            )}
          </Stack>
          <Divider />
          <Stack paddingY={2}>
            <TextField
              placeholder="AI-model-from-prod"
              label={<Label text={'AI Model Name'} required />}
              value={fileName}
              onChange={(event) => {
                const newValue = event.target.value?.trimStart();

                setFileName(newValue);
                setError(newValue ? '' : 'This field is required');
              }}
              error={!!error}
              helperText={error}
            />
          </Stack>
          <Divider />
          <Stack pt={2}>
            <Typography variant="subtitle1" color={theme.palette.secondary[300]}>
              <Typography component="span" variant="inherit" color={theme.palette.secondary[100]}>
                Important:{' '}
              </Typography>
              If decision points are missing from your dataset, the AI inference will not produce any decisions.
            </Typography>
            <Typography variant="subtitle1" color={theme.palette.secondary[300]} paddingTop={0.5}>
              Would you like to proceed with importing AI model?
            </Typography>
          </Stack>
        </Stack>
      }
    />
  ) : (
    <></>
  );
}

export default ModelUploadConfirmModal;
