import { useTheme } from '@mui/material';
import { Stack, Pagination as MUIPagination, Typography, Select, MenuItem } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const PAGE_SIZES = [6, 10, 20, 50, 100] as const;

export type TPageSize = (typeof PAGE_SIZES)[number];

type TPaginationProps = {
  page: number;
  total: number;
  pageSize: TPageSize;
  onPageChange: (page: number) => void;
  onPageSizeChange?: (pageSize: TPageSize) => void;
  showText?: boolean;
  dataTestId?: string;
  canChangePageSize?: boolean;
};

function Pagination({
  page,
  total,
  pageSize,
  onPageChange,
  onPageSizeChange,
  showText = true,
  dataTestId,
  canChangePageSize = true
}: TPaginationProps) {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const { rangeStart, rangeEnd } = useMemo(() => {
    return {
      rangeStart: total === 0 ? 0 : (page - 1) * pageSize + 1,
      rangeEnd: page * pageSize > total ? total : page * pageSize
    };
  }, [total, page, pageSize]);

  const areMultiplePages = total > pageSize;

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" data-test-id={dataTestId}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
        {areMultiplePages && onPageSizeChange && canChangePageSize && (
          <Select value={pageSize} onChange={(event) => onPageSizeChange(Number(event.target.value) as TPageSize)}>
            {PAGE_SIZES.map((size) => (
              <MenuItem key={size} value={size}>
                <span>{`${size} / page`}</span>
              </MenuItem>
            ))}
          </Select>
        )}
        {showText && (
          <Stack direction="row" gap={0.5}>
            <Typography variant="subtitle1" color={theme.palette.secondary[100]}>{`${rangeStart} - ${rangeEnd}`}</Typography>
            <Typography variant="subtitle1" color={theme.palette.secondary[300]}>
              {t('common:outOf')}
            </Typography>
            <Typography variant="subtitle1" color={theme.palette.secondary[100]} data-test-id={`${dataTestId}-total-entries`}>
              {total}
            </Typography>
            <Typography variant="subtitle1" color={theme.palette.secondary[300]}>
              {t('common:entries')}
            </Typography>
          </Stack>
        )}
      </Stack>
      {areMultiplePages && (
        <MUIPagination
          count={Math.ceil(total / pageSize)}
          variant="outlined"
          shape="rounded"
          page={page}
          onChange={(_, newPage) => onPageChange(newPage)}
          sx={{
            'li:last-child > .MuiPaginationItem-root': {
              marginRight: 0
            }
          }}
        />
      )}
    </Stack>
  );
}

export default Pagination;
